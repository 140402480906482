import React from "react";
import { graphql } from "gatsby";
import { useThemeUI } from "@theme-ui/core";

import ArticleThumbnail from "../components/commons/ArticleThumbnail";
import ThumbnailGrid from "../components/organisms/ThumbnailGrid";
import IndexPage from "../components/organisms/IndexPage";

export default function EventsPage({ data, pageContext }) {
  const { sanityPage, allSanityEvent } = data;
  const { theme } = useThemeUI();

  const highlightedItems = 2;
  const nodes = [...allSanityEvent.nodes];
  const featuredNodes = pageContext.skip === 0 ? nodes.splice(0, 2) : nodes;

  return (
    <IndexPage
      title={sanityPage.seoTitle || sanityPage.title}
      description={sanityPage.seoDescription}
      pathname={sanityPage.slug.current}
      page={sanityPage}
      itemCount={allSanityEvent.totalCount - highlightedItems}
      pageSize={pageContext.limit}
      currentPage={pageContext.skip / pageContext.limit + 1}
    >
      <ThumbnailGrid sx={{ mt: "gridGap", mb: 7 }}>
        {pageContext.skip === 0 && (
          <ThumbnailGrid.Featured>
            {featuredNodes.map((node) => (
              <ArticleThumbnail
                key={node.description}
                href={node.url}
                to={
                  node.url || `/${sanityPage.slug.current}/${node.slug.current}`
                }
                title={node.description}
                subtitle={
                  node.toDay
                    ? `${node.fromDay} - ${node.toDay} ${node.from}`
                    : `${node.fromDay} ${node.from}`
                }
                media={node.heroImage}
                sizes={`(max-width: ${theme.breakpoints.minSize}) 100vw, 800px`}
                loading="eager"
              />
            ))}
          </ThumbnailGrid.Featured>
        )}
        <ThumbnailGrid.Thumbnails>
          {nodes.map((node) => (
            <ArticleThumbnail
              key={node.description}
              to={`/${sanityPage.slug.current}/${node.slug.current}`}
              title={node.description}
              subtitle={
                node.toDay
                  ? `${node.fromDay} - ${node.toDay} ${node.from}`
                  : `${node.fromDay} ${node.from}`
              }
              media={node.heroImage}
              sizes={`(max-width: ${theme.breakpoints.minSize}) 100vw, 400px`}
            />
          ))}
        </ThumbnailGrid.Thumbnails>
      </ThumbnailGrid>
    </IndexPage>
  );
}

export const pageQuery = graphql`
  query EventsPage($limit: Int!, $skip: Int!) {
    sanityPage(_id: { eq: "events" }) {
      title
      subtitle
      seoTitle
      seoDescription
      slug {
        current
      }
      heroButton {
        ...Link
      }
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      blocks {
        ...Blocks
      }
    }
    allSanityEvent(
      sort: { fields: from, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        ...EventThumbnail
      }
    }
  }
`;
